import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <div id="page-not-found">
      <SEO title="404: Not found" />
      <h1>Page not found!</h1>
      <p>
        It appears that you're lost! Don't worry, here's a <a href="/">
        conveniently placed link</a> to take you home.
      </p>
    </div>
)

export default NotFoundPage
